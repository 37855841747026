"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VesselRider = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var hooks_1 = require("@digital-motors-boatyard/common-frontend/dist/hooks");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var sendQuoteEmail_1 = require("../../api/sendQuoteEmail");
var Analytics_1 = require("../../context/Analytics");
var DealSheet_1 = require("../../context/DealSheet");
var Locked_1 = require("../../context/Locked");
var RiderComponent_1 = require("../../context/RiderComponent");
var User_1 = require("../../context/User");
var useInitializePricing_1 = require("../../hooks/useInitializePricing");
var DealBuilder_1 = require("../deal-builder/DealBuilder");
var QuoteBuilder_1 = require("../quote-builder/QuoteBuilder");
var QuoteReview_1 = require("../QuoteReview");
var RiderLocked_1 = require("./RiderLocked");
var VesselRiderContent = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n  padding: ", ";\n"], ["\n  flex-grow: 1;\n  padding: ", ";\n"])), ui_theme_1.spacing.xs);
var VesselRider = function (_a) {
    var onClose = _a.onClose, onDone = _a.onDone, leadCallback = _a.leadCallback;
    var _b = (0, User_1.useUser)(), signInUser = _b.signInUser, isRegistered = _b.isRegistered;
    var _c = (0, DealSheet_1.useDealSheet)(), dealSheet = _c.dealSheet, updateDealSheet = _c.updateDealSheet, refreshDealSheet = _c.refreshDealSheet;
    var funnelStep = dealSheet.funnelStep, isSubmitted = dealSheet.isSubmitted;
    var _d = (0, RiderComponent_1.useRiderApplication)(), riderView = _d.riderView, setRiderView = _d.setRiderView;
    var trackEvent = (0, Analytics_1.useAnalytics)().trackEvent;
    var isLocked = (0, Locked_1.useIsLocked)();
    var shouldTriggerLeadCallback = (0, react_1.useRef)(false);
    var _e = (0, react_1.useState)(false), leadCallbackPoked = _e[0], pokeLeadCallback = _e[1];
    var updateView = (0, react_1.useCallback)(function (newView) {
        setRiderView(isSubmitted || funnelStep === enums_1.FunnelStep.NEXT_STEPS ? 'quote' : newView);
    }, [setRiderView, funnelStep, isSubmitted]);
    var sendQuote = (0, react_1.useCallback)(function () {
        shouldTriggerLeadCallback.current = true;
        (0, sendQuoteEmail_1.sendQuoteEmail)(String(dealSheet.id));
        pokeLeadCallback(!leadCallbackPoked); // hacky way to trigger the leadcallback useEffect since the ref won't trigger it
        trackEvent('rider_configure_submit');
        trackEvent('rider_lead_converted');
        updateView('summary');
    }, [dealSheet.id, leadCallbackPoked, trackEvent, updateView]);
    (0, react_1.useEffect)(function () {
        if (!isRegistered || !(shouldTriggerLeadCallback === null || shouldTriggerLeadCallback === void 0 ? void 0 : shouldTriggerLeadCallback.current))
            return;
        shouldTriggerLeadCallback.current = false;
        if (leadCallback) {
            leadCallback(dealSheet);
        }
    }, [dealSheet, isRegistered, leadCallback, leadCallbackPoked]);
    (0, hooks_1.useEffectOnMount)(function () { return trackEvent('rider_launch'); });
    (0, useInitializePricing_1.useInitializePricing)();
    var locked = isLocked(dealSheet.condition);
    return (react_1.default.createElement(VesselRiderContent, null,
        locked && react_1.default.createElement(RiderLocked_1.RiderLocked, null),
        !locked && riderView === 'deal' && (react_1.default.createElement(DealBuilder_1.DealBuilder, { onGetQuote: function (comment) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!comment) return [3 /*break*/, 2];
                            return [4 /*yield*/, updateDealSheet({ comment: comment }, true)];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            if (isRegistered) {
                                sendQuote();
                            }
                            else {
                                signInUser({
                                    callback: function () {
                                        sendQuote();
                                        refreshDealSheet();
                                    },
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            }); }, onClose: onClose })),
        !locked && riderView === 'summary' && (react_1.default.createElement(QuoteReview_1.QuoteReview, { onEditDeal: function () {
                updateView('deal');
            }, onNextSteps: function () {
                trackEvent('rider_quote_submit');
                updateDealSheet({
                    funnelStep: enums_1.FunnelStep.NEXT_STEPS,
                });
                updateView('quote');
            } })),
        !locked && riderView === 'quote' && (react_1.default.createElement(QuoteBuilder_1.QuoteBuilder, { onCreditAppSubmitted: function () {
                return (shouldTriggerLeadCallback.current = true);
            }, onDone: onDone }))));
};
exports.VesselRider = VesselRider;
var templateObject_1;
